'use client'

import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMutation as useApolloMutation } from '@apollo/client'
import { useLazyQuery } from '../../core/hook/useLazyQuery'
import { Cart } from '../../domain/cart'
import { CartDocument } from '../../queries/Cart.gql'
import { CreateEmptyCartDocument } from '../../queries/CreateEmptyCart.gql'
import useCurrentLocale from '../../core/hook/useCurrentLocale'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { handleError } from '../../core/hook/utils'

export type ContextResponse =
  | {
      success: true
      cart?: Cart
    }
  | {
      success: false
      error: string
      cart?: Cart
    }

export type CartContextType = {
  cart: Cart
  /* eslint-disable no-unused-vars */
  setCart: (cart: Cart) => void
  resetCart: () => void
  createCart: () => Promise<string | null>
  /* eslint-disable no-unused-vars */
  hydrateCart: (cartId: string | null) => void
}

export const defaultCart = {
  id: null,
  email: null,
  items: [],
  applied_coupons: [],
  with_card: false,
  gift_message: {
    message: null,
  },
  prices: {
    discounts: [],
    applied_taxes: [],
    grand_total: {
      value: 0,
      currency: 'EUR',
    },
  },
  shipping_addresses: [],
  billing_address: null,
  available_payment_methods: [],
  delivery_date: 0,
  delivery_time: '',
  gtm_categories: '',
}

export const CartContext = createContext<CartContextType>({
  cart: defaultCart,
  setCart: () => {},
  resetCart: () => {},
  createCart: () =>
    new Promise((resolve) => {
      resolve(null)
    }),
  /* eslint-disable no-unused-vars */
  hydrateCart: () => {},
})

const CartProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [createEmptyCart] = useApolloMutation(CreateEmptyCartDocument)
  const locale = useCurrentLocale()
  const [cart, setCart] = useState<Cart>(() => {
    if (typeof localStorage !== 'undefined') {
      const localCart =
        getCookie('cart') && (JSON.parse(localStorage.getItem('cart') ?? '{}') as Cart)

      if (localCart && localCart.id) {
        return localCart
      }
    }

    return defaultCart
  })

  const [getCart] = useLazyQuery(CartDocument, {
    fetchPolicy: 'no-cache',
  })

  const createCart = useCallback(
    async (): Promise<string | null> =>
      //use Apollo Mutation because useMutation have bug a this moment and userToken is undefined
      createEmptyCart({
        context: {
          headers: {
            Authorization: getCookie('userToken') ? `Bearer ${getCookie('userToken')}` : '',
            Store: locale,
          },
        },
      })
        .then((data) => {
          if (data.data?.createEmptyCart) {
            return data.data?.createEmptyCart
          }

          return null
        })
        .catch((error) => {
          handleError(error, false)

          return null
        }),
    [createEmptyCart],
  )

  const resetCart = useCallback(() => {
    setCart(defaultCart)
    deleteCookie('cart', {
      path: '/' + locale,
    })
    localStorage.setItem('cart', JSON.stringify(defaultCart))
  }, [])

  useEffect(() => {
    const cartId = getCookie('cart') as string

    if (cartId) {
      hydrateCart(cartId)
    }
  }, [])

  useEffect(() => {
    if (cart.id) {
      localStorage.setItem('cart', JSON.stringify(cart))
      setCookie('cart', cart.id, {
        path: '/' + locale,
      })
    }
  }, [cart])

  const hydrateCart = useCallback(
    async (cartId: string | null) => {
      getCart({
        variables: {
          cartId: String(cartId) || cart.id || '',
        },
      })
        .then((response) => {
          if (response.data?.cart) {
            //@ts-ignore
            setCart(response.data.cart)
          } else {
            throw 'cart not found'
          }
        })
        .catch((error) => {
          setCart(defaultCart)
          handleError(error, false)
        })
    },
    [getCart],
  )

  const context = useMemo(
    () => ({
      cart,
      resetCart,
      createCart,
      setCart,
      hydrateCart,
    }),
    [cart, resetCart, createCart, setCart],
  )

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>
}

export default CartProvider
